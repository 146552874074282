/**
 * Create by zhuzhaoqing
 * Description: index
 * Date: 2023/3/31 10:22
 **/
import router from '@/router'
import { defineStore } from 'pinia'
import { setFrontUrlFetch, ssoLoginFetch, ssoLogoutFetch } from '@/api/login'
import { awaitWrap, reportEvent, reportInit } from '@/common'

// 定义更详细的类型
interface MenuItem {
  menuId?: string
  parentId?: string
  menuAdr?: string
  menuName?: string
  children?: MenuItem[]
}

interface UserState {
  userInfo: string
  isCollapsed: boolean
  cachedName: string[]
  NoticeInfo: any
  menuData: MenuItem[]
}

export const useStore = defineStore('main', {
  state: (): UserState => ({
    userInfo: sessionStorage.getItem('userInfo') || '{"menu":[]}',
    isCollapsed: false,
    cachedName: [],
    NoticeInfo: undefined,
    menuData: []
  }),

  getters: {},

  actions: {
    handleMenu(menuStr: string): MenuItem[] {
      const menu = JSON.parse(menuStr)?.menu || []
      const parentMenu: MenuItem[] = []
      
      menu?.forEach((item: MenuItem) => {
        if (!item.parentId) {
          parentMenu.push({ ...item, children: [] })
        }
      })
      
      menu?.forEach((item: MenuItem) => {
        parentMenu.forEach((parentItem) => {
          if (item.parentId === parentItem.menuId) {
            parentItem.children?.push(item)
          }
        })
      })
      
      return parentMenu
    },

    setCachedName(n: string[]) {
      this.cachedName = n
    },

    setNoticeInfo(obj: any) {
      this.NoticeInfo = obj
    },

    async ssoLogin() {
      if (process.env['NODE_ENV'] === 'development' && process.env['VUE_APP_ISJOINT'] === 'false') {
        const param = {
          frontUrl: `${process.env.VUE_APP_FRONT_URL}`,
          frontErrUrl: `${process.env.VUE_APP_FRONT_ERRURL}`,
          frontLogoutUrl: `${process.env.VUE_APP_FRONT_LOGOUTURL}`,
        }
        const [err, data] = await awaitWrap(setFrontUrlFetch(param))
        if (data) await this.ssoLoginAfter()
        if (err) console.log('%c[index.ts] - 55', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', err)
      } else {
        await this.ssoLoginAfter()
      }
    },

    async ssoLoginAfter() {
      try {
        const { code, activeUserInfo } = await ssoLoginFetch()
        if (code === '0000') {
          reportInit(activeUserInfo)
          if (activeUserInfo.menu?.length) {
            this.userInfo = JSON.stringify(activeUserInfo)
            this.menuData = this.handleMenu(JSON.stringify(activeUserInfo))
            const menuNames = activeUserInfo.menu.map((item: MenuItem) => item.menuAdr)
            const currentPath = window.location.hash.split('#')[1]?.split('/')[1]
            const isDev = process.env.NODE_ENV === 'development'
            
            if (!isDev && !menuNames.includes(currentPath)) {
              router.push({
                name: this.menuData?.[0]?.children?.[0]?.menuAdr,
              })
            }
            
            sessionStorage.setItem('userInfo', JSON.stringify(activeUserInfo))
          } else {
            router.push({
              name: 'error',
              query: { code: '9001' },
            })
            reportEvent({
              eventName: 'ui_open',
              properties: {
                ui_name: 'error页面打开',
              },
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    async ssoLogout() {
      try {
        sessionStorage.removeItem('userInfo')
        await ssoLogoutFetch()
        await this.ssoLogin()
      } catch (e) {
        console.log(e)
      }
    },

    changeCol() {
      this.isCollapsed = !this.isCollapsed
    }
  }
})
