// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Report from '@dbt/report'
import { message } from 'ant-design-vue'

/**
 * Create by zhuzhaoqing
 * Description: index
 * Date: 2023/7/5 11:51
 **/
export const OK = '0'
export const dateFormat = 'YYYY-MM-DD HH:mm:ss'
export const dateFormatNoTime = 'YYYY-MM-DD'

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key)
}

export const setSessionStorage = (key: string, value: string) => {
  return sessionStorage.setItem(key, value)
}

export const clearSessionStorage = (key: string) => {
  return sessionStorage.removeItem(key)
}

export const copy = (e: any, type = 'input') => {
  if (!e || e.trim().length == 0) {
    message.warning('不可复制空值')
  } else if (e) {
    let Url2 = e
    let oInput: any = document.createElement(type)
    oInput.value = Url2
    document.body.appendChild(oInput)
    oInput.select() // 选择对象
    document.execCommand('Copy') // 执行浏览器复制命令
    oInput.className = 'oInput'
    oInput.style.display = 'none'
    message.success('复制成功')
  }
}

export const filterOption = (input: string, option: any) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const resetData = (formName: any, initForm: any) => {
  const keys = Object.keys(formName)
  let obj: { [name: string]: string } = {}
  keys.forEach((item) => {
    obj[item] = initForm[item]
  })
  Object.assign(formName, obj)
}

// 处理async try catch
export async function awaitWrap<T, U = any>(promise: Promise<T>): Promise<[U | null, T | null]> {
  try {
    const data: T = await promise;
    return [null, data];
  } catch (err) {
    return [err as U, null]
  }
}

// 下拉组件不随页面滚动
export const getNode = (triggerNode: any) => triggerNode.parentNode || document.body

// 处理导出文件 blob格式
export const handleExportFile = (data: any, name: string, type = 'xlsx') => {
  let blob = new Blob([data], { type: 'application/vnd.ms-excel' })
  let url = window.URL.createObjectURL(blob)
  let downloadElement = document.createElement('a')
  downloadElement.href = url
  downloadElement.download = `${name}.${type}`
  downloadElement.click()
  URL.revokeObjectURL(downloadElement.href)
}

// 事件上报
export const reportEvent = (params: any) => {
  if (process.env.VUE_APP_IS_SENTRY === 'true') {
    Report.reportEvent(params)
  } else {
    console.log('%c[index.ts] - 77', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', params)
  }
}

// 事件初始化
export const reportInit = (info: any) => {
  let params = {
    username: info.username, //登录账号
    dbtId: 'f571145af9250ef7416c79e53', //
    chnl: info?.parentOrgNames?.join('/') + '/' + info?.department, //部门详情
    name: info.name, //中文名称
  }
  if (process.env.VUE_APP_IS_SENTRY === 'true') {
    Report.init(params)
  } else {
    console.log('%c[index.ts] - 95', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', params)
  }
}

// 首字母大写
export const firstUpperCase = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
